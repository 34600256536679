import * as client_hooks from '../../../src/hooks.client.ts';


export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26')
];

export const server_loads = [0,2];

export const dictionary = {
		"/(app)": [~6,[2],[3]],
		"/(app)/admins": [~7,[2],[3]],
		"/(app)/bgadmins": [~8,[2],[3]],
		"/(app)/companies": [~9,[2],[3]],
		"/(app)/companies/[companyId]": [~10,[2],[3]],
		"/(app)/companies/[companyId]/vessels/[vesselId]": [~11,[2],[3]],
		"/(app)/crew": [~12,[2],[3]],
		"/(app)/crew/crew-member/[crewmemberId]": [~13,[2],[3]],
		"/(auth)/login": [~19,[4],[5]],
		"/(auth)/login/forgot-password": [~20,[4],[5]],
		"/(auth)/login/forgot-password/verify-otp": [~21,[4],[5]],
		"/(app)/logs": [~14,[2],[3]],
		"/(auth)/onboarding/complete-registration": [~22,[4],[5]],
		"/(auth)/onboarding/request-otp": [~23,[4],[5]],
		"/(auth)/onboarding/verify-otp": [~24,[4],[5]],
		"/(app)/orders": [~15,[2],[3]],
		"/(app)/orders/[orderId]": [~16,[2],[3]],
		"/(app)/search": [~17,[2],[3]],
		"/(auth)/update-password": [~25,[4],[5]],
		"/(auth)/update-password/success": [26,[4],[5]],
		"/(app)/vessels/[vesselId]": [~18,[2],[3]]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),
	init: client_hooks.init,
	reroute: (() => {}),
	transport: {}
};

export const decoders = Object.fromEntries(Object.entries(hooks.transport).map(([k, v]) => [k, v.decode]));

export const hash = false;

export const decode = (type, value) => decoders[type](value);

export { default as root } from '../root.svelte';